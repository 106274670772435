<template>
    <swiper :navigation="true" :modules="modules" class="mySwiper">

        <swiper-slide v-for="(item, index) in prescription" @click="todetail(item)">
            <div class="backimg">
                <img :src="'https://zhny.yun-bo.net/' + item.avatar" alt="">
            </div>
            <div class="swipertitleNr">
                <div class="t">
                  {{item.title.slice(0,5)}}
                    <br />
                  {{item.title.slice(5)}}
                </div>
                <div class="en">{{item.content}}</div>
                <div class="icon">
                  <div class="img" v-if="index == 0">
                    <div class="lsyd"></div>
                  </div>
                  <div class="img" v-if="index == 1">
                    <img src="../assets/trgl.png" alt="" />
                  </div>
                  <div class="img" v-if="index == 2">
                    <img src="../assets/zhny.png" alt="" />
                  </div>
                </div>
            </div>
        </swiper-slide>
      


    </swiper>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { API } from "../../api/index";
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { ref, onMounted,watch } from 'vue';
import { useMainStore } from '@/store/index.js'
import {useRouter} from "vue-router";
export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
      const router = useRouter()
        let prescription = ref([])
        let mainStore = useMainStore();
        watch(mainStore, (newVal) => {
            console.log('swiper数据',newVal.data.prescription);
            prescription.value = newVal.data.prescription

        })
       
        function todetail(item) {
          console.log(item)
          if (item.title == '社会化服务解决方案'){
            router.push('/socializedservices')
          }
          if (item.title == '土壤改良解决方案'){
            router.push('/Soilimprovement')
          }
          if (item.title == '智慧农业解决方案'){
            router.push('/SmartAgriculture')
          }
        }
        return {
            modules: [Navigation],
            prescription,
            todetail
        };
    },
};
</script>

<style lang="scss" scoped>
html,
body {
    position: relative;
    height: 100%;
}

body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
}

.swiper {
    width: 100%;
    height: 100%;
    background-color: #000;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
 
    background-size: 100% 100%;
    background-repeat: no-repeat;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}
.backimg{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    img{
        width: 100%;
        height: 100%;
    }
}
.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

::v-deep .swiper-button-prev,
.swiper-button-next {
    color: #fff !important;
}

::v-deep .swiper-button-next {
    color: #fff !important;
}

.swipertitleNr {
    .t {
        font-size: 3rem;
        font-weight: 700;
        letter-spacing: 0px;
        color: rgba(255, 255, 255, 1);
    }

    .en {
        font-size: 2rem;
        font-weight: 700;
        letter-spacing: 0px;
        color: rgba(255, 255, 255, 1);
        margin-top: 14px;
    }
}

.swipertitleNr .icon {
    display: flex;
    justify-content: center;
    margin-top: 2.916666666666667vw;
    transition: all 0.3s;
    position: relative;
    margin-top: 81px;
}

.swipertitleNr .icon .img {
    width: 48px;
    height: 48px;
    background: #fff;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    z-index: 2;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    .lsyd {
        left: 8.36px;
        top: 9.32px;
        width: 19.4px;
        height: 19.99px;
        opacity: 1;
        background: rgba(64, 186, 50, 1);
        border-radius: 100%;
    }

    img {
        width: 70%;
        height: 70%;
    }
}

.swipertitleNr .icon:before {
    content: "";
    background: #fff;
    position: absolute;
    top: 55%;
    left: 50%;
    border-radius: 50%;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    width: 66px;
    height: 66px;
    margin-top: -33px;
    margin-left: -33px;
    filter: alpha(opacity=30);
    -moz-opacity: 0.3;
    -khtml-opacity: 0.3;
    opacity: 0.3;
    z-index: 1;
    animation: mymoveli2 1s ease-out infinite;
}

.swipertitleNr .icon:after {
    content: "";
    background: #fff;
    position: absolute;
    top: 55%;
    left: 50%;
    border-radius: 50%;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    width: 82px;
    height: 82px;
    margin-top: -42px;
    margin-left: -42px;
    filter: alpha(opacity=10);
    -moz-opacity: 0.1;
    -khtml-opacity: 0.1;
    opacity: 0.1;
    z-index: 0;
    animation: mymoveli1 1s ease-out infinite;
}

@keyframes mymoveli2 {
    from {
        transform: scale3d(0.9, 0.9, 1);
    }

    to {
        transform: scale3d(1.2, 1.2, 1);
    }
}

@keyframes mymoveli1 {
    from {
        transform: scale3d(0.9, 0.9, 1);
    }

    to {
        transform: scale3d(1.3, 1.3, 1);
    }
}
</style>