<template>
  <div id="app" >
    <!-- 头部导航栏 -->
    <topnav ref="navChild"   :isscrolled="scrolled"   ></topnav>
    <el-backtop :right="100" :bottom="100" />
    <RouterView />
<!--     <test ></test>-->
     <bottom ></bottom>

  </div>


</template>
<script setup>


import {  RouterView,useRoute } from 'vue-router'
// import autofit from "./views/js/auto.js"
import { ref,onUnmounted,onMounted ,watch} from 'vue';
import topnav from './views/topnav.vue'
import test from './views/test.vue'

import bottom  from './views/footer.vue'
const route = useRoute(); // 获取当前路由
const currentPath = ref(route.path); // 当前路由路径

// 监听路由的变化
watch(
    () => route.path,
    (newVal) => {
      currentPath.value = newVal;
      if (currentPath.value === '/'){
        localStorage.setItem('rouTerPage',0)
      }else  if(currentPath.value === '/InternetThings'){
        localStorage.setItem('rouTerPage',1)
      }
    },
    { immediate: true }
);


let propsbool = ref(false)
function onprops() {
  propsbool.value = !propsbool.value

}
onMounted(()=> {
  window.addEventListener('scroll', handleScroll);


})
// 在组件卸载时移除滚动事件监听器  
onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
});

// function getShareInfo() {
//   wx.config({
//     appId: 'wxd0aad0894eb54896',  // appID 公众号的唯一标识
//     timestamp: config.timestamp, // 生成签名的时间戳
//     nonceStr: config.nonceStr, //  生成签名的随机串
//     signature: config.signature, // 生成的签名
//     jsApiList: [
//       'updateAppMessageShareData', // 分享到朋友
//       'updateTimelineShareData', // 分享到朋友圈
//     ]
//   });
//
// }

const navChild = ref()
let scrolled = ref(false);
// 判断滚动条
function handleScroll() {
    let scrollY =  document.documentElement.scrollTop || document.body.scrollTop;
  if (scrollY> 0) {
    scrolled.value = true;
    localStorage.setItem('isscrolled', scrolled.value)
    navChild.value.getisscroll()
  } else {
    scrolled.value = false;
    localStorage.setItem('isscrolled', scrolled.value)
    navChild.value.getisscroll()
  }
}
// 在组件卸载时移除滚动事件监听器  
onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
 
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

*,div {
  margin: 0;
  padding: 0;
}
li{
  list-style: none;
}

nav {
  /* 默认的导航栏样式 */
  transition: background-color 0.3s; /* 平滑过渡背景颜色 */
}
.color-change {
  /* 滚动后导航栏的样式 */
  background-color: #fff !important; /* 假设变成黑色背景 */
}
</style>
