import {createApp, ref} from 'vue'
import App from './App.vue'
import router from './router'
import countTo from 'vue3-count-to'
import ElementPlus from "element-plus";
import './views/js/rem.js'; //在main.js中引入rem.js
import "element-plus/dist/index.css"; // 引入css
import VueQrcode from 'vue-qrcode'
import soilimpro from './components/soilimpro.vue'
import { createPinia } from 'pinia'
const pinia = createPinia()
import {API} from "../api/index";
import wx from "weixin-js-sdk";
router.beforeEach((to, from, next) => {
    console.log(to, '78787')
    let navdata = ref(null)
    navdata.value = JSON.parse(localStorage.getItem('navdata'))
    let winUrl = window.location.href.split("#")[0];
    console.log(winUrl, '=================================================')

    API.get_wx_config().then(res=>{
        let title = ref('')
        let desc = ref('')
        let imgUrl = ref('')
        let link = ref('')
        console.log(res.data.data,'787878977777777777777777777777777')
        wx.config({
            debug: false,
            appId:res.data.data.appId, // 必填，公众号的唯一标识
            timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.data.noncestr, // 必填，生成签名的随机串
            signature: res.data.data.signature, // 必填，签名，见附录1
            jsApiList: ["updateTimelineShareData", "updateAppMessageShareData"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        });
        if (to.path == '/pay2'){
            title.value  =   navdata.value[1].next[1].title
            desc.value  =   navdata.value[1].next[1].content
            imgUrl.value = 'https://zhny.yun-bo.net' + navdata.value[1].next[1].avatar
            link.value = navdata.value[1].next[1].link_url
        }
        else if (to.path == '/pay'){
            title.value  =   navdata.value[1].next[2].title
            desc.value  =  navdata.value[1].next[2].content
            imgUrl.value = 'https://zhny.yun-bo.net' + navdata.value[1].next[2].avatar
            link.value = navdata.value[1].next[2].link_url
        }
        else  if (to.path == '/SmartAgriculture'){
            title.value  =   navdata.value[1].next[0].title
            desc.value  =   navdata.value[1].next[0].content
            imgUrl.value = 'https://zhny.yun-bo.net' + navdata.value[1].next[0].avatar
            link.value = navdata.value[1].next[0].link_url
        }
        else if (to.path == '/socializedservices'){
            title.value  =   navdata.value[1].next[3].title
            desc.value  =   navdata.value[1].next[3].content
            imgUrl.value = 'https://zhny.yun-bo.net' + navdata.value[1].next[3].avatar
            link.value = navdata.value[1].next[3].link_url
        }
        else if (to.path == '/Soilimprovement'){
            title.value  =   navdata.value[1].next[4].title
            desc.value  =   navdata.value[1].next[4].content
            imgUrl.value = 'https://zhny.yun-bo.net' + navdata.value[1].next[4].avatar
            link.value = navdata.value[1].next[4].link_url
        }
        else if (to.path == '/InternetThings'){
            title.value  =   navdata.value[2].content
            desc.value  =   navdata.value[2].remark
            imgUrl.value = 'https://zhny.yun-bo.net' + navdata.value[2].avatar
            link.value = navdata.value[2].link_url
        }
        else if (to.path == '/SmartOrchard'){
            title.value  =   navdata.value[2].next[0].title
            desc.value  =   navdata.value[2].next[0].content
            imgUrl.value = 'https://zhny.yun-bo.net' + navdata.value[2].next[0].avatar
            link.value = navdata.value[2].next[0].link_url
        }
        else if (to.path == '/Smartirrigation'){
            title.value  =   navdata.value[2].next[1].title
            desc.value  =   navdata.value[2].next[1].content
            imgUrl.value = 'https://zhny.yun-bo.net' + navdata.value[2].next[1].avatar
            link.value = navdata.value[2].next[1].link_url
        }
        else if (to.path == '/MilitaryCreation'){
            title.value  =   navdata.value[3].title
            desc.value  =   navdata.value[3].remark
            imgUrl.value = 'https://zhny.yun-bo.net' + navdata.value[3].avatar
            link.value = navdata.value[3].link_url
        }
        else if (to.path == '/contactus'){
            title.value  =  navdata.value[4].title;
            desc.value  =   navdata.value[4].remark
            imgUrl.value = 'https://zhny.yun-bo.net' + navdata.value[4].avatar
            link.value = navdata.value[4].link_url
        }
        else if (to.path == '/aboutny'){
            title.value  =  navdata.value[5].title;
            desc.value  =   navdata.value[5].remark
            imgUrl.value = 'https://zhny.yun-bo.net' + navdata.value[5].avatar
            link.value = navdata.value[5].link_url
        }else if(to.path == '/'){
            title.value  =  navdata.value[0].title;
            desc.value  =   navdata.value[0].remark
            imgUrl.value = 'https://zhny.yun-bo.net' + navdata.value[0].avatar
            link.value = navdata.value[0].link_url
        }
        wx.ready(function () {
            //分享到朋友圈  需在用户可能点击分享按钮前就先调用
            wx.updateTimelineShareData({
                title: title.value, // 分享时的标题
                desc: desc.value, // 分享描述
                link: link.value, // 分享时的链接
                imgUrl: imgUrl.value, // 分享图标
                success: function () {
                    // 设置成功
                    console.log("分享到朋友圈成功");
                },
                cancel: function () {
                    // 取消设置
                    console.log("分享到朋友圈取消");
                },
                fail: function () {
                    console.log("分享朋友圈失败");
                },
            });
            // 分享给朋友的设置
            wx.updateAppMessageShareData({
                title: title.value, // 分享时的标题
                desc: desc.value, // 分享描述
                link: link.value, // 分享时的链接
                imgUrl: imgUrl.value, // 分享图标
                success: function () {
                    // 设置成功
                    console.log("分享给朋友成功");
                },
                cancel: function () {
                    // 设置失败
                    console.log("分享给朋友失败");
                },
                fail: function () {
                    console.log("分享朋友圈失败");
                },
            });
        });




    })


    // console.log(navdata.value[3].content,'----------------------------------------------')
    //  if (to.path == '/pay2'){
    //      let titleElement = document.getElementsByTagName('title')[0];
    //      // 获取 title 的文本内容
    //      let titleText = titleElement.textContent;
    //       // 打印原始 title 的文本内容
    //      console.log('原始标题:', titleText);
    //     // 修改 title 的文本内容
    //      titleElement.textContent = navdata.value[1].next[1].content;
    //    // 获取并打印修改后的 title 的文本内容
    //      let newTitleText = titleElement.textContent;
    //      console.log('修改后的标题:', newTitleText);
    //  }


    next(); // 确保调用 next() 方法以确保导航继续进行。
});

createApp(App).use(router).use(ElementPlus).use(countTo).use(pinia).component('soilimpro', soilimpro).component('vue-qrcode', VueQrcode).mount('#app')
