import { get, post, deletes, put } from '../axios/index'

let ip = 'https://zhny.yun-bo.net/snswap'

export const API = {
    getquanjing(data) {
        return get(`${ip}/index.php?act=api&op=get_website_data`, data)
    },
    // 提交用户信息保存订单
    Submitinfo(data) {


        return get(`https://zhny.yun-bo.net/mobile/index.php?act=member_payment_hsb_yb_zhny&op=set_order`, data)
    },
    // 公司简介
    getsubpage(data) {
        return get(`${ip}/index.php?act=api&op=get_subpage_data`, data)
    },
    // 查询订单
    aigcorder(data) {
        return get(`${ip}/index.php?act=api&op=get_aigc_order`, data)
    },
    // 获取地图经纬度
    get_map(data) {
        return get(`${ip}/index.php?act=api&op=get_map`, data)
    },
    // 获取微信配置分享好友
    get_wx_config(data) {
        return get(`${ip}/index.php?act=api&op=get_wx_config`, data)
    }
}
